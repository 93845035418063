import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Login from "./pages/login";
import Dashboard from "./pages/admin/dashboard/page";
import { ProtectedRoutes } from "./services/protectedRoutes";
import Layouts from "./pages/admin/layouts";
import Settings from "./pages/admin/settings/page";
import Session from "./pages/admin/session/page";
import View from "./pages/admin/session/view";
import Enrollment from "./pages/admin/enrollment/page";
import Page from "./pages/admin/enrollment/filter/page";
import Documents from "./pages/admin/session/documents/page";

// student routes
import LayoutStudent from "./pages/student/layouts";
import DashboardStudent from "./pages/student/dashboard/page";
import EnrollmentStudent from "./pages/student/enrollment/page";
import ProfileStudent from "./pages/student/profile/page";

import PageNotFound from "./pages/not-found";

export default function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/admin" element={<Layouts />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="settings" element={<Settings />} />
            <Route path="session" element={<Session />} />
            <Route path="session/:id" element={<View />} />
            <Route path="session/:periodId/documents" element={<Documents />} />

            <Route path="re-enrollment" element={<Enrollment />} />
            <Route path="re-enrollment/filter" element={<Page />} />
          </Route>

          {/* student route */}
          <Route path="/student" element={<LayoutStudent />}>
            <Route index element={<DashboardStudent />} />
            <Route path="re-enrollment" element={<EnrollmentStudent />} />
            <Route path="profile" element={<ProfileStudent />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}
