import { useState } from "react";
import { Button } from "../../../../components/ui/button";
import { Pencil } from "lucide-react";

import ModalBiodata from "./modalBiodata";
import { StudentInformation } from "../../../../types";
import ItemValue from "./item-value";

interface BiodataProps {
  data: StudentInformation | null;
}

const Biodata = ({ data }: BiodataProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <div className="w-full mb-10">
      <div className="w-full flex items-center p-4 bg-sky-50 justify-between border-l-4 border-sky-700 ">
        <div>
          <h1 className="font-medium text-xl text-sky-700">Informasi umum</h1>
          <p className="text text-xs text-muted-foreground ">
            Biodata dan alamat anda
          </p>
        </div>
        <Button variant="primary" onClick={() => setIsModalOpen(true)}>
          <Pencil className="h-4 w-4 mr-2" />
          Ubah/lengkapi
        </Button>
      </div>
      {data === null && (
        <div className="w-full p-4 flex items-center justify-center bg-sky-50 mt-2 ">
          <p className="text-sm text-muted-foreground">Data tidak ditemukan</p>
        </div>
      )}

      {data !== null && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 mt-2 bg-sky-50 p-4">
          <ItemValue label="Tempat Lahir" value={data.place_of_birth} />
          <ItemValue label="Tanggal Lahir" value={data.date_of_birth} />
          <ItemValue label="Jenis Kelamin" value={data.gender} />
          <ItemValue label="Agama" value={data.religion} />
          <ItemValue label="Kewarganegaraan" value={data.nationality} />
          <ItemValue label="NIK" value={data.nik} />
          <ItemValue label="NISN" value={data.nisn} />
          <ItemValue label="NPWP" value={data.npwp} />
          <ItemValue label="Jalan" value={data.road} />
          <ItemValue label="Dusun" value={data.hamlet} />
          <ItemValue label="Kelurahan" value={data.subdistrict} />
          <ItemValue label="Kecamatan" value={data.district} />
          <ItemValue label="Jenis Tinggal" value={data.residential_type} />
          <ItemValue label="Alat Transportasi" value={data.transportation} />
          <ItemValue label="Telepon" value={data.telphone} />
          <ItemValue label="No. HP/WA" value={data.phone_number} />
          <ItemValue label="Penerima KPS" value={data.kps_beneficiary} />
          <ItemValue
            label="Alamat pengiriman serdik"
            value={data.serdik_shipping_address}
          />
          <ItemValue
            label="Nama Tempat sekolah PPL"
            value={data.practical_training_location}
          />
          <ItemValue
            label="No. rekening Kepala sekolah"
            value={data.school_principals_account_number}
          />
          <ItemValue
            label="Nama Kepala Sekolah/Penganggun Jawab PPL"
            value={data.field_experience_supervisor}
          />
        </div>
      )}

      {isModalOpen && (
        <ModalBiodata
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          initialValue={data}
        />
      )}
    </div>
  );
};

export default Biodata;
