import { Eye } from "lucide-react";

import { TableCell } from "../../../../components/ui/table";
import { Button } from "../../../../components/ui/button";
import { Document } from "../../../../types";
import { Badge } from "../../../../components/ui/badge";
import { BACKEND_URL } from "../../../../utils/backendUrl";

interface DocumentItemProps {
  document: Document;
  index: number;
}

const DocumentItem = ({ document, index }: DocumentItemProps) => {
  const handlePreview = (path: string | null) => {
    const realPath = BACKEND_URL + "/storage/" + path;

    if (path) {
      window.open(realPath, "_blank");
    }

    return;
  };

  return (
    <>
      <TableCell className="font-medium">{index + 1}</TableCell>
      <TableCell className="text-xs font-semibold">{document.name}</TableCell>
      <TableCell>
        {document.isRequired === 1 ? (
          <Badge variant="primary">Wajib</Badge>
        ) : (
          <Badge variant={"outline"}>Optional</Badge>
        )}
      </TableCell>
      <TableCell>
        {document.isUploaded ? (
          <Badge className="bg-green-600">Selesai</Badge>
        ) : (
          <Badge variant={"destructive"}>Belum</Badge>
        )}
      </TableCell>
      <TableCell>
        {document.isUploaded && (
          <div className="flex items-center gap-x-2">
            <Button onClick={() => handlePreview(document.path)} size="xs">
              <Eye className="h-4 w-4 mr-2" />
              Lihat
            </Button>
          </div>
        )}
      </TableCell>
    </>
  );
};

export default DocumentItem;
