import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Button } from "../../../../../components/ui/button";
import { PlusCircle, RotateCw } from "lucide-react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../../components/ui/alert-dialog";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../components/ui/form";

import { Input } from "../../../../../components/ui/input";
import { Checkbox } from "../../../../../components/ui/checkbox";
import api from "../../../../../services/api";
import { useToast } from "../../../../../components/ui/use-toast";

const items = [
  {
    id: "pdf",
    label: "PDF",
  },
  {
    id: "image",
    label: "image",
  },
  {
    id: "docx",
    label: "docx",
  },
  {
    id: "xlsx",
    label: "xlsx",
  },
] as const;

const formSchema = z.object({
  name: z.string().min(2, {
    message: "Judul harus diisi.",
  }),
  required: z.boolean(),
  items: z.array(z.string()).refine((value) => value.some((item) => item), {
    message: "Kamu harus memilih tipe validasi, minimal 1",
  }),
});

const Create = ({ periodId }: { periodId: string }) => {
  const { toast } = useToast();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      required: false,
      items: ["pdf"],
    },
  });

  const toggleModal = () => setIsOpen((current) => !current);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);

    await api
      .post(`period-setting/documents/${periodId}`, values)
      .then(({ data }) => {
        toggleModal();
        form.reset();
        window.location.reload();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className="mt-5">
      <AlertDialog open={isOpen}>
        <AlertDialogTrigger>
          <Button onClick={toggleModal} className="">
            <PlusCircle className="w-4 h-4 mr-2" />
            Buat baru
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Tambah data mahasiswa</AlertDialogTitle>
            <AlertDialogDescription>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem className="mt-6">
                        <FormLabel>Nama</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="items"
                    render={() => (
                      <FormItem>
                        <div className="mb-4 mt-7">
                          <FormLabel>Validasi</FormLabel>
                          <FormDescription>
                            Silahkan pilih validasi untuk dokumen
                          </FormDescription>
                        </div>

                        {items.map((item) => (
                          <FormField
                            key={item.id}
                            control={form.control}
                            name="items"
                            render={({ field }) => {
                              return (
                                <FormItem
                                  key={item.id}
                                  className="flex flex-row items-start space-x-3 space-y-0"
                                >
                                  <FormControl>
                                    <Checkbox
                                      checked={field.value?.includes(item.id)}
                                      onCheckedChange={(checked) => {
                                        return checked
                                          ? field.onChange([
                                              ...field.value,
                                              item.id,
                                            ])
                                          : field.onChange(
                                              field.value?.filter(
                                                (value) => value !== item.id
                                              )
                                            );
                                      }}
                                    />
                                  </FormControl>
                                  <FormLabel className="text-sm font-normal">
                                    {item.label}
                                  </FormLabel>
                                </FormItem>
                              );
                            }}
                          />
                        ))}
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="required"
                    render={({ field }) => (
                      <FormItem className="mt-6">
                        <FormLabel>Wajib di isi ?</FormLabel>
                        <FormControl className="flex flex-col">
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <div className="flex justify-end mt-6 w-full gap-x-4">
                    <Button
                      variant={"ghost"}
                      disabled={isLoading}
                      type="button"
                      onClick={toggleModal}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isLoading}>
                      {isLoading && (
                        <RotateCw className="mr-2 h-4 w-4 animate-spin" />
                      )}
                      Continue
                    </Button>
                  </div>
                </form>
              </Form>
            </AlertDialogDescription>
          </AlertDialogHeader>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default Create;
