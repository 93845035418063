import React from "react";

const PageNotFound = () => {
  return (
    <div className="min-h-screen w-full flex items-center justify-center flex-col">
      <h2 className="font-bold text-xl">404</h2>
      <p className="mt-3 text-gray-700 font-semibold">
        Halaman Tidak Ditemukan
      </p>
    </div>
  );
};

export default PageNotFound;
