import { useState } from "react";
import { Button } from "../../../../components/ui/button";
import { Pencil } from "lucide-react";
import { ParentInformation } from "../../../../types";
import ModalParent from "./modal-parent";
import ItemValue from "./item-value";

interface ParentProps {
  data: ParentInformation | null;
}

const Parent = ({ data }: ParentProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <div className="w-full mb-10">
      <div className="w-full flex items-center p-4 bg-sky-50 justify-between border-l-4 border-sky-700 ">
        <div>
          <h1 className="font-medium text-xl text-sky-700">
            Data orang tua/wali
          </h1>
          <p className="text text-xs text-muted-foreground ">
            Biodata dan dan informasi lainnya
          </p>
        </div>
        <Button variant="primary" onClick={() => setIsModalOpen(true)}>
          <Pencil className="h-4 w-4 mr-2" />
          Ubah/lengkapi
        </Button>
      </div>
      {data === null && (
        <div className="w-full p-4 flex items-center justify-center bg-sky-50 mt-2">
          <p className="text-sm text-muted-foreground">Data tidak ditemukan</p>
        </div>
      )}

      {data !== null && (
        <>
          <h3 className="font-bold text-sm text-sky-700 mb-2 mt-6">
            DATA AYAH
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 mt-2 bg-sky-50 p-4">
            <ItemValue label="Nama" value={data.father_name} />
            <ItemValue label="NIK" value={data.father_nik} />
            <ItemValue
              label="Tanggal Lahir"
              value={data.father_date_of_birth}
            />
            <ItemValue label="Pekerjaan" value={data.father_job} />
            <ItemValue
              label="Pendidikan Terakhir"
              value={data.father_education_level}
            />
            <ItemValue label="Penghasilan" value={data.father_income} />
          </div>

          <h3 className="font-bold text-sm text-sky-700 mb-2 mt-6">DATA IBU</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 mt-2 bg-sky-50 p-4">
            <ItemValue label="Nama" value={data.mother_name} />
            <ItemValue label="NIK" value={data.mother_nik} />
            <ItemValue
              label="Tanggal Lahir"
              value={data.mother_date_of_birth}
            />
            <ItemValue label="Pekerjaan" value={data.mother_job} />
            <ItemValue
              label="Pendidikan Terakhir"
              value={data.mother_education_level}
            />
            <ItemValue label="Penghasilan" value={data.mother_income} />
          </div>
        </>
      )}

      {isModalOpen && (
        <ModalParent
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          initialValue={data}
        />
      )}
    </div>
  );
};

export default Parent;
