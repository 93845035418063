import { Compass, Layout, Puzzle, Settings, Users } from "lucide-react";

import { SidebarItem } from "./siderbar-item";

const routes = [
  {
    icon: Layout,
    label: "Dashboard",
    href: "/admin/dashboard",
  },
  {
    icon: Users,
    label: "Lapor diri",
    href: "/admin/re-enrollment",
  },
  {
    icon: Puzzle,
    label: "Sesi",
    href: "/admin/session",
  },
];

export const SidebarRoutes = () => {
  return (
    <div className="flex flex-col w-full">
      {routes.map((route) => (
        <SidebarItem
          key={route.href}
          icon={route.icon}
          label={route.label}
          href={route.href}
        />
      ))}
    </div>
  );
};
