import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../../services/api";
import { useToast } from "../../../../components/ui/use-toast";
import { Skeleton } from "../../../../components/ui/skeleton";
import Create from "./_components/create";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import TableItem from "./_components/table-item";

type DocumentsType = {
  id: string;
  name: string;
  type_files: [];
  isRequired: number;
  isActive: number;
};

type PeriodType = {
  id: string;
  category_name: string;
  wave: string;
  year: string;
};

const Documents = () => {
  const { periodId } = useParams();
  const { toast } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<DocumentsType[]>([]);
  const [period, setPeriod] = useState<PeriodType | null>(null);

  useEffect(() => {
    getDocumentsPeriod();
  }, []);

  const getDocumentsPeriod = async () => {
    setIsLoading(true);
    await api
      .get("period-setting/documents/" + periodId)
      .then(({ data }) => {
        setDocuments(data.data.documents);
        setPeriod(data.data.period_setting);
        console.info(data.data.documents);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="container mx-auto max-w-6xl p-4">
      <h1 className="text-2xl font-bold">Pengaturan dokumen</h1>
      <p className="text-sm text-muted-foreground">
        Silahkan atur pengaturan dokumen yang akan di upload oleh mahasiswa,{" "}
        <span className="font-bold">
          {period?.category_name}, gelombang {period?.wave}, tahun{" "}
          {period?.year}
        </span>
      </p>

      <div className="w-full mt-7">
        {isLoading && <SkeletonLoading />}

        {!isLoading && documents.length < 1 && period && (
          <EmptyData id={period?.id} />
        )}
        {!isLoading && documents.length > 0 && (
          <div>
            <div className="mb-4">
              {period && <Create periodId={period?.id} />}
            </div>
            <Table className="border">
              <TableHeader>
                <TableRow>
                  <TableHead>Judul</TableHead>
                  <TableHead>Tipe file</TableHead>
                  <TableHead>Upload status</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Aksi</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {documents.map((document, index) => (
                  <TableItem
                    id={document.id}
                    name={document.name}
                    type_files={document.type_files}
                    isRequired={document.isRequired}
                    isActive={document.isActive}
                  />
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Documents;

const SkeletonLoading = () => {
  return (
    <div className="flex flex-col gap-y-2">
      <Skeleton className="h-[40px]" />
      <Skeleton className="h-[400px]" />
    </div>
  );
};

const EmptyData = ({ id }: { id: string }) => {
  return (
    <div className="w-full h-[400px] border rounded flex items-center justify-center flex-col">
      Tidak ada dokumen
      <Create periodId={id} />
    </div>
  );
};
