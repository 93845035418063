import { Camera } from "lucide-react";

interface StudentAvatarProps {
  name: string;
}

const StudentAvatar = ({ name }: StudentAvatarProps) => {
  return (
    <div className="relative md:w-1/4 w-full h-[311px] rounded-r  bg-sky-50 flex flex-col items-center justify-center">
      <div
        onClick={() => {}}
        className="absolute z-0 top-2 right-2 h-8 w-8 bg-sky-700  rounded-full flex items-center justify-center cursor-pointer hover:bg-sky-600 transition-colors"
      >
        <Camera className="h-6 w-6 text-white " />
      </div>
      <img src="/images/no-profile.svg" alt="gambar" className="w-[200px]" />
      <h3 className="mt-4 uppercase text-sm font-bold">{name}</h3>
    </div>
  );
};

export default StudentAvatar;
