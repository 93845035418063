import { ParentInformation } from "../../../../types";
import ItemValue from "../../../student/enrollment/_components/item-value";
import Heading from "./heading";

interface StudentParentsProps {
  data?: ParentInformation | null;
}

const StudentParents = ({ data }: StudentParentsProps) => {
  if (data === null || data === undefined) {
    return (
      <div className="w-full p-4 flex items-center justify-center bg-sky-50 mt-2">
        <p className="text-sm text-muted-foreground">
          Data orang tua tidak ditemukan
        </p>
      </div>
    );
  }

  return (
    <div>
      {data !== null && (
        <div className="mt-10">
          <Heading
            heading="Data orang tua"
            description="Data orang ayah & ibu"
          />

          <h3 className="font-bold text-sm text-sky-700 mb-2 mt-6">
            DATA AYAH
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 mt-2 bg-sky-50 p-4">
            <ItemValue label="Nama" value={data.father_name} />
            <ItemValue label="NIK" value={data.father_nik} />
            <ItemValue
              label="Tanggal Lahir"
              value={data.father_date_of_birth}
            />
            <ItemValue label="Pekerjaan" value={data.father_job} />
            <ItemValue
              label="Pendidikan Terakhir"
              value={data.father_education_level}
            />
            <ItemValue label="Penghasilan" value={data.father_income} />
          </div>

          <h3 className="font-bold text-sm text-sky-700 mb-2 mt-6">DATA IBU</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 mt-2 bg-sky-50 p-4">
            <ItemValue label="Nama" value={data.mother_name} />
            <ItemValue label="NIK" value={data.mother_nik} />
            <ItemValue
              label="Tanggal Lahir"
              value={data.mother_date_of_birth}
            />
            <ItemValue label="Pekerjaan" value={data.mother_job} />
            <ItemValue
              label="Pendidikan Terakhir"
              value={data.mother_education_level}
            />
            <ItemValue label="Penghasilan" value={data.mother_income} />
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentParents;
