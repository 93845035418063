import React from "react";
import { Skeleton } from "../../../../components/ui/skeleton";

const SkeletonLoading = () => {
  return (
    <div className="w-full">
      <div className="flex justify-between items-center">
        <Skeleton className="h-8 w-[250px]" />
        <div className="flex items-center gap-x-2">
          <Skeleton className="h-8 w-[40px]" />
          <Skeleton className="h-8 w-[100px]" />
        </div>
      </div>
      <div className="mt-10 flex flex-col gap-y-2">
        <Skeleton className="h-8 w-full" />
        <Skeleton className="h-8 w-full" />
        <Skeleton className="h-8 w-full" />
        <Skeleton className="h-8 w-full" />
        <Skeleton className="h-8 w-full" />
      </div>
    </div>
  );
};

export default SkeletonLoading;
