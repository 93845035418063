import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import Create from "./_components/Create";
import api from "../../../services/api";
import { Button } from "../../../components/ui/button";
import { Eye, PackageOpen, RotateCw, Settings } from "lucide-react";
import { Badge } from "../../../components/ui/badge";
import { Link } from "react-router-dom";

type DatasProps = {
  id: string;
  wave_period: string;
  start_date: string;
  end_date: string;
  year: string;
  category: {
    id: string;
    name: string;
  };
};

const Session = () => {
  const [datas, setDatas] = useState<DatasProps[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getPeriodSetting();
  }, []);

  const getPeriodSetting = async () => {
    setLoading(true);
    await api
      .get("/period-setting")
      .then(({ data }) => {
        setDatas(data.data);
      })
      .catch((err) => {
        console.info(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="container mx-auto max-w-[1400px] p-4">
      <h1 className="text-2xl font-bold">Pengaturan sesi</h1>
      <p className="text-sm text-muted-foreground">
        Silahkan atur pembukaan sesi pendaftaran program ppg
      </p>

      <div className="w-full mt-10">
        <Create />

        <Table className="border">
          <TableHeader>
            <TableRow>
              <TableHead className="w-[80px]">No.</TableHead>
              <TableHead>Kategori</TableHead>
              <TableHead>Gelombang</TableHead>
              <TableHead>Tanggal Pembukaan</TableHead>
              <TableHead>Tanggal Penutupan</TableHead>
              <TableHead>Pengaturan dokumen</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Aksi</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {datas.length > 0 &&
              datas.map((item, index) => (
                <TableRow key={index}>
                  <TableCell className="font-medium">{index + 1}</TableCell>
                  <TableCell>
                    <Badge>{item.category.name}</Badge>
                  </TableCell>
                  <TableCell>{item.wave_period}</TableCell>
                  <TableCell>{item.start_date}</TableCell>
                  <TableCell>{item.end_date}</TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <Badge variant="destructive">Not setup</Badge>
                      <Link to={`/admin/session/${item.id}/documents`}>
                        <Settings className="w-5 h-5 ml-3 cursor-pointer hover:text-sky-800" />
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Badge variant="destructive">Close</Badge>
                  </TableCell>
                  <TableCell>
                    <Link to={`/admin/session/${item.id}`}>
                      <Button variant={"ghost"}>
                        <Eye className="w-4 h-4 mr-2" />
                        Detail
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {datas.length < 1 && !isLoading && (
          <div className="h-[300px] flex items-center justify-center border flex-col space-y-4 ">
            <PackageOpen className="w-10 h-10 text-muted-foreground" />
            <p className="text-muted-foreground">Tidak ada data</p>
          </div>
        )}

        <div className="w-full flex items-center justify-center py-10">
          {isLoading && <RotateCw className="mr-2 h-4 w-4 animate-spin" />}
        </div>
      </div>
    </div>
  );
};

export default Session;
