import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../../components/ui/alert";
import { AlertCircle, CheckCircle2, XCircle } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import Biodata from "./_components/biodata";
import { ParentInformation, StudentInformation } from "../../../types";
import api from "../../../services/api";
import { Skeleton } from "../../../components/ui/skeleton";
import { useToast } from "../../../components/ui/use-toast";
import Parent from "./_components/parent";
import Documents from "./_components/documents";

type UserProps = {
  id: string;
  name: string;
};

const Page = () => {
  const { toast } = useToast();
  const [user, setUser] = useState<UserProps | null>(null);
  const [information, setInformation] = useState<StudentInformation | null>(
    null
  );
  const [parents, setParents] = useState<ParentInformation | null>(null);
  const [documentsStatus, setDocumentsStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getStudentData();
  }, []);

  const getStudentData = async () => {
    setIsLoading(true);
    await api
      .get("student/information")
      .then(({ data }) => {
        setInformation(data.data.information);
        setParents(data.data.parents);
        setDocumentsStatus(data.data.isAllUploadedDocuments);
        setUser(data.data.user);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="container mx-auto max-w-5xl mb-20">
      {isLoading && <LoadingSkeleton />}

      {!isLoading && (
        <div>
          <Alert className="bg-yellow-100 mt-7 border border-yellow-400">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Hallo {user?.name}!</AlertTitle>
            <AlertDescription>
              Silakan melengkapi informasi yang telah kami sediakan dibawah ini.
            </AlertDescription>
          </Alert>

          <div className="mt-10">
            <Table className="border border-sky-200 rounded-md">
              <TableHeader className="bg-sky-50 ">
                <TableRow>
                  <TableHead>Komponen</TableHead>
                  <TableHead>Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>Data diri</TableCell>
                  <TableCell>
                    {information !== null ? (
                      <CheckCircle2 className="w-5 h-5 text-green-500" />
                    ) : (
                      <XCircle className="w-5 h-5 text-red-500" />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Data orang tua/wali</TableCell>
                  <TableCell>
                    {parents !== null ? (
                      <CheckCircle2 className="w-5 h-5 text-green-500" />
                    ) : (
                      <XCircle className="w-5 h-5 text-red-500" />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Dokumen</TableCell>
                  <TableCell>
                    {documentsStatus !== false ? (
                      <CheckCircle2 className="w-5 h-5 text-green-500" />
                    ) : (
                      <XCircle className="w-5 h-5 text-red-500" />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="mt-10">
            <Biodata data={information} />
            <Parent data={parents} />
            <Documents />
          </div>
        </div>
      )}
    </div>
  );
};

export default Page;

const LoadingSkeleton = () => {
  return (
    <div>
      <Skeleton className="h-[192px] w-full mb-10 mt-6" />
      <Skeleton className="h-[70px] w-full mb-7" />
      <Skeleton className="h-[192px] w-full" />
      <Skeleton className="h-[70px] w-full mt-7" />
      <Skeleton className="h-[70px] w-full mt-7 mb-20" />
    </div>
  );
};
