import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { Document } from "../../../../types";
import DocumentItem from "./document-item";
import Heading from "./heading";

interface StudentDocumentProps {
  data?: Document[] | [];
}

const StudentDocument = ({ data }: StudentDocumentProps) => {
  if (data === null || data === undefined) {
    return (
      <div className="w-full p-4 flex items-center justify-center bg-sky-50 mt-2">
        <p className="text-sm text-muted-foreground">
          Data dokumen tidak ditemukan
        </p>
      </div>
    );
  }

  return (
    <div className="w-full mb-10 mt-10">
      <Heading heading="Dokumen" description="Dokumen upload mahasiswa" />
      {data.length < 1 && (
        <div className="w-full p-4 flex items-center justify-center bg-sky-50 mt-2 ">
          <p className="text-sm text-muted-foreground">Data tidak ditemukan</p>
        </div>
      )}

      {data.length > 0 && (
        <Table className="border mt-6">
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">No.</TableHead>
              <TableHead>Judul</TableHead>
              <TableHead>status dokumen</TableHead>
              <TableHead>status upload</TableHead>
              <TableHead>Aksi</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((document, index) => (
              <TableRow>
                <DocumentItem key={index} document={document} index={index} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default StudentDocument;
