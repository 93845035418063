import React from "react";

const Logo = () => {
  return (
    <div>
      <img src="/images/admin-logo.svg" alt="logo" className="w-full" />
    </div>
  );
};

export default Logo;
