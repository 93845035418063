import React from "react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../../components/ui/alert";
import { Terminal } from "lucide-react";

const Dashboard = () => {
  return (
    <div className="p-4">
      <Alert>
        <Terminal className="h-4 w-4" />
        <AlertTitle>Selamat datang admin!</AlertTitle>
        <AlertDescription>
          Diaplikasi ppg umkendari, dimana anda bisa manage lapor diri ppg!
        </AlertDescription>
      </Alert>
    </div>
  );
};

export default Dashboard;
