import { useState } from "react";
import { useForm } from "react-hook-form";
import { RotateCw } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { useToast } from "../../../../components/ui/use-toast";
import api from "../../../../services/api";
import { Button } from "../../../../components/ui/button";
import Heading from "./heading";

const formSchema = z
  .object({
    new_password: z.string().min(2, {
      message: "password baru wajib di isi!",
    }),
    confirm_password: z.string().min(2, {
      message: "konfirmasi password baru wajib di isi!",
    }),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    message: "Password baru dan konfirmasi password harus sama!",
    path: ["confirm_password"],
  });

const StudentChangePasswordForm = ({ studentId }: { studentId: string }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      new_password: "",
      confirm_password: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    await api
      .put("re-enrollments/student/account/" + studentId, values)
      .then(({ data }) => {
        console.info(data);
        toast({
          title: `${data.message}`,
        });
        window.location.reload();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <Heading heading="Akun" description="Manage akun mahasiswa" />
      <div className="mt-7">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col space-y-6"
          >
            <FormField
              control={form.control}
              name="new_password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password Baru</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="password"
                      placeholder="masukkan password baru"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirm_password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Konfirmasi Password</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="password"
                      placeholder="Konfirmasi password baru anda"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-10">
              <Button type="submit" disabled={isLoading}>
                {isLoading && (
                  <RotateCw className="mr-2 h-4 w-4 animate-spin" />
                )}
                Simpan
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};

export default StudentChangePasswordForm;
