import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { Button } from "../../../components/ui/button";
import { Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { Skeleton } from "../../../components/ui/skeleton";
import ViewSkeleton from "./_components/view-skeleton";
import api from "../../../services/api";
import InputForm from "./_components/input-form";
import SelectForm from "./_components/select-form";
import Delete from "./_components/delete";

type ViewProps = {
  id: string;
  wave_period: string;
  start_date: string;
  end_date: string;
  year: string;
  category: {
    label: string;
    value: string;
  };
};

type CategoryProps = {
  id: string;
  name: string;
};

const View = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [period, setPeriod] = useState<ViewProps | null>(null);
  const [categories, setCategories] = useState<CategoryProps[]>([]);

  useEffect(() => {
    getDataPeriod();
  }, []);

  const getDataPeriod = async () => {
    setIsLoading(true);
    await api
      .get(`period-setting/${id}`)
      .then(({ data }) => {
        setPeriod(data.data);
        setCategories(data.data.categories);
      })
      .catch((err) => {
        console.info(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <ViewSkeleton />;
  }

  return (
    <div className="container mx-auto max-w-4xl">
      <div className="w-full flex justify-between items-center mt-5">
        <h1 className="text-2xl font-semibold ">Sesi prajab gelombang 1</h1>
        {id && <Delete id={id} />}
      </div>
      <div className="mt-7">
        <Card>
          <CardHeader>
            <CardTitle>Statistik</CardTitle>
            <CardDescription>Evaluasi statistik mahasiswa ppg</CardDescription>
          </CardHeader>
          <CardContent>
            <p className="text-sm underline text-blue-800 cursor-pointer">
              Lihat pendaftar
            </p>
          </CardContent>
        </Card>
        <div className="mb-20">
          {!isLoading && period && (
            <div className="w-full">
              <SelectForm
                datas={categories}
                defaultValue={period.category}
                label="Kategori"
                id={period.id}
              />
              <InputForm
                id={period.id}
                label="Gelombang"
                value={period.wave_period}
                updatedCategory="wave"
                type="number"
              />

              <InputForm
                id={period.id}
                label="Tanggal mulai"
                value={period.start_date}
                type="date"
                updatedCategory=" "
              />

              <InputForm
                id={period.id}
                label="Tanggal berakhir"
                value={period.end_date}
                type="date"
                updatedCategory="end_start"
              />
              <InputForm
                id={period.id}
                label="Tahun"
                value={period.year}
                type="number"
                updatedCategory="year"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default View;
