import { useEffect, useState } from "react";
import { columns } from "./columns";
import { DataTable } from "./data-table";
import api from "../../../../services/api";
import { useToast } from "../../../../components/ui/use-toast";
import SkeletonLoading from "./skeleton-loading";

import { useLocation, useNavigate } from "react-router-dom";
import ModalImport from "./modal-import";
import ModalDetail from "./modal-detail";

type EnrollmentProps = {
  id: string;
  name: string;
  uniq_id: string;
  email: string;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Page = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { toast } = useToast();
  let query = useQuery();

  const [enrollmentData, setEnrollmentData] = useState<EnrollmentProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalImport, setModalImport] = useState<boolean>(false);
  const [modalDetail, setModalDetail] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isExport = queryParams.get("export");
    const isDetail = queryParams.get("detail");
    const d_ID = queryParams.get("d_ID");
    setSelectedId(d_ID);

    if (isExport === "true") {
      setModalImport(true);
    }
    if (isDetail !== null && d_ID !== null) {
      setModalDetail(true);
    }
  }, [location]);

  useEffect(() => {
    getEnrollments();
  }, []);

  const toogleModal = (): void => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("export");
    navigate(location.pathname + "?" + queryParams.toString(), {
      replace: true,
    });
    setModalImport(!modalImport);
  };

  const toogleModalEdit = (): void => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete("detail");
    queryParams.delete("d_ID");
    navigate(location.pathname + "?" + queryParams.toString(), {
      replace: true,
    });
    setModalDetail(!modalDetail);
  };

  const category = query.get("category");
  const wave = query.get("wave");
  const year = query.get("year");

  const getEnrollments = async () => {
    setIsLoading(true);
    await api
      .get("re-enrollments", {
        params: {
          category: category,
          wave: wave,
          year: year,
        },
      })
      .then(({ data }) => {
        setEnrollmentData(data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="container mx-auto max-w-6xl p-4">
      <h1 className="text-3xl font-bold">Lapor diri</h1>
      <p className="text-sm text-muted-foreground">
        Kelola pendaftaran PPG dan lihat daftar mahasiswa yang telah mendaftar
        ulang di sini.
      </p>

      <div className="w-full mt-7">
        {!isLoading && <DataTable columns={columns} data={enrollmentData} />}
        {isLoading && <SkeletonLoading />}
      </div>

      <ModalImport isOpen={modalImport} onClose={toogleModal} />
      {modalDetail && selectedId && (
        <ModalDetail
          isOpen={modalDetail}
          onClose={toogleModalEdit}
          id={selectedId}
        />
      )}
    </div>
  );
};

export default Page;
