import { RotateCw } from "lucide-react";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";

import api from "../services/api";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Button } from "../components/ui/button";
import { useToast } from "../components/ui/use-toast";
import { Input } from "../components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form";
import { useNavigate } from "react-router-dom";

const formSchema = z.object({
  uniqId: z.string().min(1, {
    message: "username harus diisi!",
  }),
  password: z.string().min(1, {
    message: "password harus diisi!",
  }),
});

const settings = {
  dots: true,
  autoplay: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 4000,
};

const Home = () => {
  const { toast } = useToast();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const token = Cookies.get("refreshToken");

    if (token) {
      navigate("/admin/dashboard");
    }
  }, [navigate]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      uniqId: "",
      password: "",
    },
  });

  const { isValid } = form.formState;

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setIsLoading(true);

    await api
      .post("auth/login", values)
      .then(({ data }) => {
        const { role } = data.user;

        if (role === "Admin") {
          window.location.assign("/admin/dashboard");
          Cookies.set("refreshToken", data.token);
        } else if (role === "Student") {
          window.location.assign("/student");
          Cookies.set("refreshToken", data.token);
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      className="min-h-screen bg-cover "
      style={{ backgroundImage: "url('/images/bg-login.png')" }}
    >
      <div className="container mx-auto max-w-6xl h-screen pt-10 flex rounded-xl overflow-hidden">
        <div className="h-[600px] lg:w-1/2 w-full bg-white p-8">
          <div className="flex items-center gap-x-3">
            <img
              src="/images/logo-kemdikbud.png"
              alt="logo"
              className="w-[50px]"
            />
            <img src="/images/logo-umk.png" alt="logo" className="w-[200px]" />
          </div>
          <div className="mt-5">
            <p className="text-sm">Selamat datang</p>
            <h2 className="text-xl font-bold">di Lapor Diri PPG</h2>
            <p className="text-sm">Universitas Muhammadiyah Kendari</p>
            <p className="text-sm mt-3 font-thin">
              Silahkan masukkan username & password anda
            </p>
          </div>
          <div className="mt-6">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormField
                  control={form.control}
                  name="uniqId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Username</FormLabel>
                      <FormControl>
                        <Input
                          disabled={isLoading}
                          placeholder="Masukkan username"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem className="mt-5">
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <Input
                          disabled={isLoading}
                          type="password"
                          placeholder="Masukkan password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex items-center gap-x-2 mt-5">
                  <Button
                    disabled={!isValid || isLoading}
                    type="submit"
                    className="w-full"
                    variant={"primary"}
                  >
                    {isLoading && (
                      <RotateCw className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Masuk
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </div>
        <div className="h-[600px] lg:w-1/2 hidden lg:block bg-bittersweetShimmer">
          <Slider {...settings}>
            <div className="w-full h-[600px] object-cover">
              <img
                src="/images/slider1.png"
                alt="Gambar"
                className="w-full h-[600px] object-center object-cover"
              />
            </div>
            <div className="w-full h-[600px]">
              <img
                src="/images/slider2.png"
                alt="gambar"
                className="w-full h-[600px] object-center object-cover"
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Home;
