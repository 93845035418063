import { Toaster } from "./components/ui/toaster";
import AppRoutes from "./routes";

function App() {
  return (
    <div className="font-inter">
      <Toaster />
      <AppRoutes />
    </div>
  );
}

export default App;
