import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";

import { Button } from "../../../../components/ui/button";
import { PlusCircle, RotateCw } from "lucide-react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../components/ui/alert-dialog";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/ui/form";
import { useToast } from "../../../../components/ui/use-toast";

import { Input } from "../../../../components/ui/input";
import { useState } from "react";
import api from "../../../../services/api";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../../../components/ui/alert";
import { useLocation } from "react-router-dom";

const formSchema = z.object({
  name: z.string().min(2, {
    message: "Nama harus diisi.",
  }),
  email: z.string(),
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Create = () => {
  let query = useQuery();

  const { toast } = useToast();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
    },
  });

  const toggleModal = () => setIsOpen((current) => !current);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);

    let dataUpdate = {
      ...values,
      category: query.get("category"),
      wave: query.get("wave"),
      year: query.get("year"),
    };

    await api
      .post("re-enrollments", dataUpdate)
      .then(({ data }) => {
        window.location.reload();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div>
      <AlertDialog open={isOpen}>
        <AlertDialogTrigger>
          <Button onClick={toggleModal} className="">
            <PlusCircle className="w-4 h-4 mr-2" />
            Buat baru
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Tambah data mahasiswa</AlertDialogTitle>
            <AlertDialogDescription>
              <Alert className="bg-yellow-100 border border-yellow-600">
                <AlertTitle>Penting!</AlertTitle>
                <AlertDescription>
                  Username dan password akan di generate otomatis oleh sistem!
                </AlertDescription>
              </Alert>

              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem className="mt-6">
                        <FormLabel>Nama</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className="mt-6">
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input type="email" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex justify-end mt-6 w-full gap-x-4">
                    <Button
                      variant={"ghost"}
                      disabled={isLoading}
                      type="button"
                      onClick={toggleModal}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isLoading}>
                      {isLoading && (
                        <RotateCw className="mr-2 h-4 w-4 animate-spin" />
                      )}
                      Continue
                    </Button>
                  </div>
                </form>
              </Form>
            </AlertDialogDescription>
          </AlertDialogHeader>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default Create;
