import { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useRef } from "react";

import { RotateCw, X } from "lucide-react";
import { AlertDialogHeader } from "../../../../components/ui/alert-dialog";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "../../../../components/ui/dialog";

import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import { useToast } from "../../../../components/ui/use-toast";
import api from "../../../../services/api";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  reloadData: () => void;
}

const ModalProfileFicture = ({ isOpen, onClose, reloadData }: ModalProps) => {
  const { toast } = useToast();

  const [file, setFile] = useState<File | null>(null);
  const [editorVisible, setEditorVisible] = useState<boolean>(false);
  const editorRef = useRef<AvatarEditor | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (!file.type.startsWith("image/")) {
        toast({
          title: `Ekstensi file harus gambar!`,
          variant: "destructive",
        });
        return;
      }
      setFile(file);
      setEditorVisible(true);
    }
  };

  const handleCancel = () => {
    setEditorVisible(false);
    setFile(null);
  };

  const handleSave = async () => {
    if (file === null) return;

    setIsLoading(true);

    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob(async (blob) => {
        if (blob) {
          const formData = new FormData();

          formData.append("image", blob, "profile_picture.png");

          try {
            await api.post("/student/profile/avatar", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });
            toast({
              title: "Sukses!",
              description: "Profile berhasil di perbaharui!",
            });
            reloadData();
            handleCancel();
            onClose();
          } catch (error) {
            console.error("Failed to upload image:", error);
          } finally {
            setIsLoading(false);
          }
        }
      });
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <AlertDialogHeader>
          <div className="w-full flex items-center justify-between ">
            <DialogTitle>Profile picture</DialogTitle>
            <DialogClose asChild onClick={onClose}>
              <Button type="button" variant="ghost" className=" rounded-full">
                <X className="h-4 w-4" />
              </Button>
            </DialogClose>
          </div>
          <DialogDescription>
            <Input accept="image/*" type="file" onChange={handleFileChange} />
          </DialogDescription>
        </AlertDialogHeader>
        {editorVisible && file && (
          <>
            <div className="w-full flex items-center justify-center">
              <AvatarEditor
                image={URL.createObjectURL(file)}
                ref={editorRef}
                width={250}
                height={250}
                border={50}
                color={[255, 255, 255, 0.6]}
                scale={1.2}
              />
            </div>
            <div className="w-full flex items-center gap-x-3">
              <Button
                onClick={handleCancel}
                className="w-1/2"
                size="lg"
                variant="ghost"
                disabled={isLoading}
              >
                Batal
              </Button>
              <Button
                disabled={isLoading}
                onClick={handleSave}
                className="w-1/2"
                size="lg"
              >
                {isLoading && (
                  <RotateCw className="mr-2 h-4 w-4 animate-spin" />
                )}
                Simpan
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalProfileFicture;
