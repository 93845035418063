import { Button } from "../../../components/ui/button";
import { Search } from "lucide-react";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import { useToast } from "../../../components/ui/use-toast";
import SkeletonLoading from "./skeleton-loading";
import { useNavigate } from "react-router-dom";

type PeriodCategoryProps = {
  id: string;
  name: string;
};

const Enrollment = () => {
  const { toast } = useToast();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [periodCategories, setPeriodCategories] = useState<
    PeriodCategoryProps[]
  >([]);
  const [waves, setWaves] = useState([]);
  const [years, setYears] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState<null | string>(null);
  const [selectedWave, setSelectedWave] = useState<null | string>(null);
  const [selectedYear, setSelectedYear] = useState<null | string>(null);

  useEffect(() => {
    getCategoriesAndPeriodSettings();
  }, []);

  const getCategoriesAndPeriodSettings = async () => {
    setIsLoading(true);
    await api
      .get("re-enrollments/filter")
      .then(({ data }) => {
        const { categories, period_settings } = data.data;
        setPeriodCategories(categories);
        setWaves(period_settings.waves);
        setYears(period_settings.years);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFilter = () => {
    if (!selectedCategory || !selectedWave || !selectedYear) {
      toast({
        title: "Silahkan isi semua input!",
        variant: "destructive",
      });
      return;
    }

    navigate(
      `/admin/re-enrollment/filter?category=${selectedCategory}&wave=${selectedWave}&year=${selectedYear}`
    );
  };

  return (
    <div className="container mx-auto max-w-6xl p-4">
      <h1 className="text-3xl font-bold">Filter </h1>
      <p className="text-sm text-muted-foreground">
        Silahkan masukkan data yang dibutuhkan untuk melihat pendaftar ulang
        ppg.
      </p>

      <div className="w-full mt-6 flex items-center">
        {isLoading && <SkeletonLoading />}
        {!isLoading && (
          <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 grid-cols-1 gap-4">
            <select
              onChange={(value) => setSelectedCategory(value.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-[200px]"
            >
              <option selected value="">
                Kategori
              </option>
              {periodCategories.map((category, index) => (
                <option value={category.id} key={index}>
                  {category.name}
                </option>
              ))}
            </select>

            <select
              onChange={(value) => setSelectedWave(value.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-[200px]"
            >
              <option selected value="">
                Gelombang
              </option>
              {waves.map((wave, index) => (
                <option value={wave} key={index}>
                  {wave}
                </option>
              ))}
            </select>

            <select
              onChange={(value) => setSelectedYear(value.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-[200px]"
            >
              <option selected value="">
                Tahun
              </option>
              {years.map((year, index) => (
                <option value={year} key={index}>
                  {year}
                </option>
              ))}
            </select>

            <Button onClick={handleFilter} className="w-max px-5">
              <Search className="w-4 h-4 mr-2" />
              Filter
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Enrollment;
