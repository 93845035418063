interface ItemValueProps {
  label: string;
  value: string;
}

const ItemValue = ({ label, value }: ItemValueProps) => {
  return (
    <div>
      <p className="text-xs text-muted-foreground font-medium">{label}</p>
      <h4 className="text-sm font-semibold capitalize">{value}</h4>
    </div>
  );
};

export default ItemValue;
