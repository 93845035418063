import React, { useState } from "react";
import { Alert, AlertDescription } from "../../../../components/ui/alert";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../../components/ui/alert-dialog";
import { Input } from "../../../../components/ui/input";
import { BACKEND_URL_API } from "../../../../utils/backendUrl";
import api from "../../../../services/api";
import { useToast } from "../../../../components/ui/use-toast";
import { useLocation } from "react-router-dom";
import { Progress } from "../../../../components/ui/progress";

interface ModalImportProps {
  isOpen: boolean;
  onClose: () => void;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ModalImport = ({ isOpen, onClose }: ModalImportProps) => {
  const { toast } = useToast();
  let query = useQuery();

  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = () => {
    if (!file) {
      toast({
        title: "File tidak boleh kosong!",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    api
      .post("re-enrollments/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          category: query.get("category"),
          wave: query.get("wave"),
          year: query.get("year"),
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          }
        },
      })
      .then((response) => {
        toast({
          title: "Berhasil import data!",
        });
        onClose();
        window.location.reload();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <AlertDialog open={isOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Import data mahasiswa</AlertDialogTitle>
            <AlertDialogDescription>
              <Alert className="bg-blue-100">
                <AlertDescription>
                  Silahkan download format import excel di{" "}
                  <a
                    href={`${BACKEND_URL_API}/download/example-format-import`}
                    download="FormatImportExcel"
                    className="text-blue-600 cursor-pointer hover:underline"
                  >
                    sini
                  </a>
                </AlertDescription>
              </Alert>

              <div className="mt-6 ">
                <Input
                  type="file"
                  id="file"
                  onChange={handleFileChange}
                  disabled={isLoading}
                />
              </div>
              <div className="mt-4">
                {isLoading && <Progress value={progress} />}
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={onClose} disabled={isLoading}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleSubmit} disabled={isLoading}>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default ModalImport;
