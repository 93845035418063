import { Outlet } from "react-router-dom";
import SideBar from "./sideBar";
import Navbar from "./navbar";

const Layouts = () => {
  return (
    <div className="w-full h-full">
      <div className="h-[80px] md:pl-56 fixed inset-y-0 w-full z-50">
        <Navbar />
      </div>
      <div className="hidden md:flex h-full w-56 flex-col fixed inset-y-0">
        <SideBar />
      </div>
      <main className="md:ml-56 pt-20">
        <Outlet />
      </main>
    </div>
  );
};

export default Layouts;
