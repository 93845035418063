import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";

import { Button } from "../../../../components/ui/button";
import { PlusCircle, RotateCw } from "lucide-react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../components/ui/alert-dialog";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/ui/form";
import { useToast } from "../../../../components/ui/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { Input } from "../../../../components/ui/input";
import { useEffect, useState } from "react";
import api from "../../../../services/api";

const formSchema = z.object({
  periodCategory: z.string().min(2, {
    message: "Category periode harus diisi.",
  }),
  wavePeriod: z.string().min(1, {
    message: "Gelombang harus diisi.",
  }),
  startDate: z.string().min(2, {
    message: "Tanggal mulai harus diisi.",
  }),
  endDate: z.string().min(2, {
    message: "Tanggal berakhir harus diisi.",
  }),
  year: z.string().min(2, {
    message: "Tahun harus diisi.",
  }),
});

type PeriodCategory = {
  id: string;
  name: string;
};

const Create = () => {
  const { toast } = useToast();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [periodCategories, setPeriodCategories] = useState<PeriodCategory[]>(
    []
  );

  useEffect(() => {
    getPeriodCategories();
  }, []);

  const getPeriodCategories = async () => {
    await api.get("period-category").then(({ data }) => {
      setPeriodCategories(data.data);
    });
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      periodCategory: "",
      wavePeriod: "",
      startDate: "",
      endDate: "",
      year: "",
    },
  });

  const toggleModal = () => setIsOpen((current) => !current);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);

    await api
      .post("period-setting", values)
      .then(({ data }) => {
        toast({
          title: `Data berhasil disimpan`,
        });
        form.reset();
        toggleModal();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div>
      <AlertDialog open={isOpen}>
        <AlertDialogTrigger>
          <Button onClick={toggleModal} className="mb-4">
            <PlusCircle className="w-4 h-4 mr-2" />
            Buat baru
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Buka sesi baru</AlertDialogTitle>
            <AlertDialogDescription>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <FormField
                    control={form.control}
                    name="periodCategory"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Kategori</FormLabel>
                        <FormControl>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <SelectTrigger className="">
                              <SelectValue placeholder="Pilih" />
                            </SelectTrigger>
                            <SelectContent>
                              {periodCategories.map((item, index) => (
                                <SelectItem value={item.name} key={index}>
                                  {item.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="wavePeriod"
                    render={({ field }) => (
                      <FormItem className="mt-6">
                        <FormLabel>Gelombang</FormLabel>
                        <FormControl>
                          <Input type="number" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="startDate"
                    render={({ field }) => (
                      <FormItem className="mt-6">
                        <FormLabel>Tanggal mulai registrasi</FormLabel>
                        <FormControl>
                          <Input type="date" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="endDate"
                    render={({ field }) => (
                      <FormItem className="mt-6">
                        <FormLabel>Tanggal berakhir registrasi</FormLabel>
                        <FormControl>
                          <Input type="date" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="year"
                    render={({ field }) => (
                      <FormItem className="mt-6">
                        <FormLabel>Tahun</FormLabel>
                        <FormControl>
                          <Input type="number" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex justify-end mt-6 w-full gap-x-4">
                    <Button
                      variant={"ghost"}
                      disabled={isLoading}
                      type="button"
                      onClick={toggleModal}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isLoading}>
                      {isLoading && (
                        <RotateCw className="mr-2 h-4 w-4 animate-spin" />
                      )}
                      Continue
                    </Button>
                  </div>
                </form>
              </Form>
            </AlertDialogDescription>
          </AlertDialogHeader>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default Create;
