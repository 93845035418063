import { Pencil, RotateCw } from "lucide-react";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";

import { Button } from "../../../../components/ui/button";
import { useToast } from "../../../../components/ui/use-toast";

import { Form, FormField } from "../../../../components/ui/form";
import api from "../../../../services/api";

const formSchema = z.object({
  value: z.string().min(1, {
    message: ` Input harus diisi!`,
  }),
});

interface SelectFormProps {
  id: string;
  label: string;
  defaultValue: {
    value: string;
    label: string;
  };
  datas: {
    id: string;
    name: string;
  }[];
  edited?: boolean;
}

const SelectForm = ({
  id,
  label,
  defaultValue,
  datas,
  edited = true,
}: SelectFormProps) => {
  const { toast } = useToast();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      value: defaultValue.value,
    },
  });

  const { isValid } = form.formState;

  const [isEditing, setIsEding] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleEdit = () => setIsEding((current) => !current);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setIsLoading(true);
    const dataUpdate = {
      ...values,
      id: id,
      type: "category",
    };

    await api
      .put(`period-setting/${id}`, dataUpdate)
      .then(({ data }) => {
        toggleEdit();
        toast({
          title: "Data baerhasil di ubah",
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="mt-6 bg-slate-100 rounded-md p-4 border">
      <div className="font-medium flex items-center justify-between">
        {label}
        {edited && (
          <Button onClick={toggleEdit} variant={"ghost"}>
            {isEditing ? (
              <>Batal</>
            ) : (
              <>
                <Pencil className="mr-2 h-4 w-4" /> Edit
              </>
            )}
          </Button>
        )}
      </div>
      {!isEditing && <p className="text-sm mt-3">{defaultValue.label}</p>}
      {isEditing && (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="value"
              render={({ field }) => (
                <select
                  {...field}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected value={""}>
                    Pilih
                  </option>
                  {datas.map((data, index) => (
                    <option value={data.id} key={index}>
                      {data.name}
                    </option>
                  ))}
                </select>
              )}
            />
            <div className="flex items-center gap-x-2 mt-4">
              <Button disabled={!isValid || isLoading} type="submit">
                {isLoading && (
                  <RotateCw className="mr-2 h-4 w-4 animate-spin" />
                )}
                Simpan
              </Button>
            </div>
          </form>
        </Form>
      )}
    </div>
  );
};

export default SelectForm;
