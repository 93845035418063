import { RotateCw, X } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";

import { ParentInformation } from "../../../../types";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { AlertDialogHeader } from "../../../../components/ui/alert-dialog";
import { Button } from "../../../../components/ui/button";
import { useState } from "react";
import { useToast } from "../../../../components/ui/use-toast";
import api from "../../../../services/api";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { EDUCATION_LEVELS, JOBS, SALARY } from "../../../../utils/constants";

interface ModalParentProps {
  isOpen: boolean;
  onClose: () => void;
  initialValue: ParentInformation | null;
}

const formSchema = z.object({
  father_name: z.string().min(2, {
    message: "Nama wajib diisi.",
  }),
  father_nik: z.string().optional(),
  father_date_of_birth: z.string().min(2, {
    message: "Tanggal lahir wajib diisi.",
  }),
  father_education_level: z.string().optional(),
  father_job: z.string().optional(),
  father_income: z.string().optional(),

  mother_name: z.string().min(2, {
    message: "Nama wajib diisi.",
  }),
  mother_nik: z.string().optional(),
  mother_date_of_birth: z.string().min(2, {
    message: "Tanggal lahir wajib diisi.",
  }),
  mother_education_level: z.string().optional(),
  mother_job: z.string().optional(),
  mother_income: z.string().optional(),
});

const ModalParent = ({ isOpen, onClose, initialValue }: ModalParentProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      father_name: initialValue?.father_name ?? "",
      father_nik: initialValue?.father_nik ?? "",
      father_date_of_birth: initialValue?.father_date_of_birth ?? "",
      father_education_level: initialValue?.father_education_level ?? "",
      father_job: initialValue?.father_job ?? "",
      father_income: initialValue?.father_income ?? "",

      mother_name: initialValue?.mother_name ?? "",
      mother_nik: initialValue?.mother_nik ?? "",
      mother_date_of_birth: initialValue?.mother_date_of_birth ?? "",
      mother_education_level: initialValue?.mother_education_level ?? "",
      mother_job: initialValue?.mother_job ?? "",
      mother_income: initialValue?.mother_income ?? "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const apiUrl = initialValue
      ? `student/information/parents/${initialValue.id}`
      : "student/information/parents";
    const method = initialValue ? "patch" : "post";

    setIsLoading(true);
    await api[method](apiUrl, values)
      .then(({ data }) => {
        window.location.reload();
        toast({
          title: `${data.message}`,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Dialog open={isOpen}>
      <DialogContent className="min-w-full h-screen  overflow-y-auto">
        <AlertDialogHeader>
          <div className="w-full flex items-center justify-between border-b-2 pb-2 ">
            <DialogTitle>Data orang tua/wali</DialogTitle>
            <DialogClose asChild onClick={onClose}>
              <Button type="button">
                <X className="h-4 w-4 mr-2" />
                Close
              </Button>
            </DialogClose>
          </div>
          <DialogDescription>
            <div className="container mx-auto max-w-5xl pt-10 mb-[200px]">
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <h3 className="font-bold text-sm text-sky-700 mb-2">
                    DATA AYAH
                  </h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 mb-10">
                    <FormField
                      control={form.control}
                      name="father_name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Nama</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="father_nik"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>NIK</FormLabel>
                          <FormControl>
                            <Input {...field} type="number" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="father_date_of_birth"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Tanggal lahir</FormLabel>
                          <FormControl>
                            <Input {...field} type="date" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="father_education_level"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Pendidikan Terakhir</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Pilih" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {EDUCATION_LEVELS.map((education, index) => (
                                <SelectItem value={education.value} key={index}>
                                  {education.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="father_job"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Pekerjaan</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Pilih" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {JOBS.map((job, index) => (
                                <SelectItem value={job.value} key={index}>
                                  {job.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="father_income"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Penghasilan</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Pilih" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {SALARY.map((item, index) => (
                                <SelectItem value={item.value} key={index}>
                                  {item.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <h3 className="font-bold text-sm text-sky-700 mb-2">
                    DATA IBU
                  </h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
                    <FormField
                      control={form.control}
                      name="mother_name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Nama</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="mother_nik"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>NIK</FormLabel>
                          <FormControl>
                            <Input {...field} type="number" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="mother_date_of_birth"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Tanggal lahir</FormLabel>
                          <FormControl>
                            <Input {...field} type="date" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="mother_education_level"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Pendidikan Terakhir</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Pilih" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {EDUCATION_LEVELS.map((education, index) => (
                                <SelectItem value={education.value} key={index}>
                                  {education.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="mother_job"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Pekerjaan</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Pilih" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {JOBS.map((job, index) => (
                                <SelectItem value={job.value} key={index}>
                                  {job.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="mother_income"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Penghasilan</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Pilih" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {SALARY.map((item, index) => (
                                <SelectItem value={item.value} key={index}>
                                  {item.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="mt-10">
                    <Button type="submit" disabled={isLoading}>
                      {isLoading && (
                        <RotateCw className="mr-2 h-4 w-4 animate-spin" />
                      )}
                      Simpan
                    </Button>
                  </div>
                </form>
              </Form>
            </div>
          </DialogDescription>
        </AlertDialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalParent;
