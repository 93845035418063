import { useEffect, useState } from "react";
import { Camera, RotateCw } from "lucide-react";

import ChangePasswordForm from "./_components/change-password-form";
import ModalProfileFicture from "./_components/modal-profile-ficture";
import api from "../../../services/api";
import { useToast } from "../../../components/ui/use-toast";
import { BACKEND_URL } from "../../../utils/backendUrl";

type UserType = {
  name: string;
  avatar_path: string | null;
};

const Page = () => {
  const { toast } = useToast();

  const [user, setUser] = useState<UserType | null>(null);
  const [modalProfileOpen, setModalProfileOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    setIsLoading(true);
    await api
      .get("student/profile")
      .then(({ data }) => {
        setUser(data.data.user);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseModal = () => {
    setModalProfileOpen(false);
  };

  return (
    <div className="container mx-auto max-w-5xl mt-7">
      <h1 className="text-3xl font-bold">Profile </h1>
      <p className="text-sm text-muted-foreground">
        Ubah profile dan password anda dengan data yang benar!
      </p>

      <div className="w-full flex md:flex-row flex-col mt-10 md:gap-x-5 gap-x-0">
        <div
          className={`relative md:w-1/3 w-full h-[311px] rounded-r border-l-8 border-sky-700 bg-sky-50 flex flex-col items-center justify-center ${
            isLoading ? "bg-gray-500 bg-opacity-50" : ""
          }`}
        >
          {isLoading && (
            <div className="absolute w-full h-full flex items-center justify-center z-30">
              <RotateCw className="mr-2 h-4 w-4 animate-spin" />
            </div>
          )}
          <div
            onClick={() => setModalProfileOpen(true)}
            className="absolute z-0 top-2 right-2 h-8 w-8 bg-sky-700  rounded-full flex items-center justify-center cursor-pointer hover:bg-sky-600 transition-colors"
          >
            <Camera className="h-6 w-6 text-white " />
          </div>

          {user?.avatar_path === null ? (
            <div className="w-[200] h-[200px] rounded-full overflow-hidden">
              <img
                src="/images/no-profile.svg"
                alt="gambar"
                className="w-[200px]"
              />
            </div>
          ) : (
            <div className="w-[200] h-[200px] rounded-full overflow-hidden">
              <img
                src={BACKEND_URL + "/storage/" + user?.avatar_path}
                alt="gambar"
                className="w-[200px]"
              />
            </div>
          )}

          <h3 className="mt-4 uppercase text-sm font-bold">{user?.name}</h3>
        </div>
        <div className="md:w-2/3 w-full md:mt-0 mt-5 md:ml-6 ml-0">
          <ChangePasswordForm />
        </div>
      </div>
      {modalProfileOpen && (
        <ModalProfileFicture
          isOpen={modalProfileOpen}
          onClose={handleCloseModal}
          reloadData={getUser}
        />
      )}
    </div>
  );
};

export default Page;
