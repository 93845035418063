import React from "react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";

const Settings = () => {
  return (
    <div className="container mx-auto max-w-7xl p-4">
      <h1 className="text-2xl font-bold">Pengaturan</h1>

      <div className="w-full mt-10">
        <Tabs defaultValue="account" className="w-[700px]">
          <TabsList>
            <TabsTrigger value="account" className="w-[300px]">
              Pembukkan sesi
            </TabsTrigger>
            <TabsTrigger value="password">Dokumen lapor diri</TabsTrigger>
            <TabsTrigger value="user">Profil</TabsTrigger>
          </TabsList>
          <TabsContent value="account">
            Make changes to your account here.
          </TabsContent>
          <TabsContent value="password">Change your password here.</TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default Settings;
