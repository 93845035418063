import { Outlet, Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const useAuth = () => {
  const userToken = Cookies.get("refreshToken");
  return Boolean(userToken);
};

export const ProtectedRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/" replace />;
};

export const ProtectedRoutesAuth = () => {
  const isAuth = useAuth();
  return isAuth ? <Navigate to="/admin/dashboard" replace /> : null;
};
