import { useEffect, useState } from "react";
import { Document } from "../../../../types";
import api from "../../../../services/api";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import DocumentItem from "./document-item";

const Documents = () => {
  const [documents, setDocuments] = useState<Document[]>([]);

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = async () => {
    await api.get("student/document").then(({ data }) => {
      setDocuments(data.data);
    });
  };

  return (
    <div className="w-full mb-10">
      <div className="w-full flex items-center p-4 bg-sky-50 justify-between border-l-4 border-sky-700 ">
        <div>
          <h1 className="font-medium text-xl text-sky-700">Dokumen</h1>
          <p className="text text-xs text-muted-foreground ">Dokumen upload</p>
        </div>
      </div>
      {documents.length < 1 && (
        <div className="w-full p-4 flex items-center justify-center bg-sky-50 mt-2 ">
          <p className="text-sm text-muted-foreground">Data tidak ditemukan</p>
        </div>
      )}

      {documents.length > 0 && (
        <Table className="border mt-6">
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">No.</TableHead>
              <TableHead>Judul</TableHead>
              <TableHead>status dokumen</TableHead>
              <TableHead>status upload</TableHead>
              <TableHead>Aksi</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {documents.map((document, index) => (
              <TableRow>
                <DocumentItem key={index} document={document} index={index} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default Documents;
