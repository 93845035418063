import { RotateCw, X } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { AlertDialogHeader } from "../../../../components/ui/alert-dialog";
import { Button } from "../../../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { useState } from "react";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../../components/ui/radio-group";
import { Textarea } from "../../../../components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import {
  RELIGIONS,
  RESIDENTIAL_TYPES,
  TRANSPORTATIONS,
} from "../../../../utils/constants";
import { useToast } from "../../../../components/ui/use-toast";
import api from "../../../../services/api";
import { StudentInformation } from "../../../../types";

interface ModalBiodataProps {
  isOpen: boolean;
  onClose: () => void;
  initialValue: StudentInformation | null;
}

const formSchema = z.object({
  place_of_birth: z.string().min(2, {
    message: "Tempat lahir wajib diisi.",
  }),
  gender: z.string().min(1, {
    message: "Jenis kelamin wajib diisi.",
  }),
  date_of_birth: z.string().min(2, {
    message: "Tanggal lahir wajib diisi.",
  }),
  religion: z.string().min(2, {
    message: "Agama wajib diisi.",
  }),
  nationality: z.string().min(2, {
    message: "Kewarganegaraan wajib diisi.",
  }),
  nik: z
    .string()
    .min(16, {
      message: "Minimal 16 Digit angka",
    })
    .max(16, {
      message: "Maksimal 16 Digit angka",
    }),
  nisn: z.string().min(2, {
    message: "NISN wajib diisi.",
  }),
  npwp: z.string().optional(),
  road: z.string().min(2, {
    message: "Alamat jalan wajib diisi.",
  }),
  hamlet: z.string().min(2, {
    message: "Dusun wajib diisi.",
  }),
  subdistrict: z.string().min(2, {
    message: "Kelurahan wajib diisi.",
  }),
  district: z.string().min(2, {
    message: "Dusun wajib diisi.",
  }),
  residential_type: z.string().min(2, {
    message: "Jenis tinggal wajib diisi.",
  }),
  transportation: z.string().min(2, {
    message: "Kendaraan wajib diisi.",
  }),
  telphone: z.string().optional(),
  phone_number: z.string().min(2, {
    message: "No. HP/WA wajib diisi.",
  }),
  kps_beneficiary: z.string().min(2, {
    message: "Penerima KPS wajib diisi.",
  }),
  serdik_shipping_address: z.string().min(2, {
    message: "Alamat pengiriman serdik wajib diisi.",
  }),
  practical_training_location: z.string().min(2, {
    message: "Nama Tempat sekolah PPL wajib diisi.",
  }),
  school_principals_account_number: z.string().min(2, {
    message: "No. rekening Kepala sekolah wajib diisi.",
  }),
  field_experience_supervisor: z.string().min(2, {
    message: "Nama Kepala Sekolah/Penganggun Jawab PPL wajib diisi.",
  }),
});

const ModalBiodata = ({ isOpen, onClose, initialValue }: ModalBiodataProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      place_of_birth: initialValue?.place_of_birth ?? "",
      gender: initialValue?.gender ?? "",
      date_of_birth: initialValue?.date_of_birth ?? "",
      religion: initialValue?.religion ?? "",
      nationality: initialValue?.nationality ?? "",
      nik: initialValue?.nik ?? "",
      nisn: initialValue?.nisn ?? "",
      npwp: initialValue?.npwp ?? "",
      road: initialValue?.road ?? "",
      hamlet: initialValue?.hamlet ?? "",
      subdistrict: initialValue?.subdistrict ?? "",
      district: initialValue?.district ?? "",
      residential_type: initialValue?.residential_type ?? "",
      transportation: initialValue?.transportation ?? "",
      telphone: initialValue?.telphone ?? "",
      phone_number: initialValue?.phone_number ?? "",
      kps_beneficiary: initialValue?.kps_beneficiary ?? "",
      serdik_shipping_address: initialValue?.serdik_shipping_address ?? "",
      practical_training_location:
        initialValue?.practical_training_location ?? "",
      school_principals_account_number:
        initialValue?.school_principals_account_number ?? "",
      field_experience_supervisor:
        initialValue?.field_experience_supervisor ?? "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    await api
      .post("student/information", values)
      .then(({ data }) => {
        window.location.reload();
        toast({
          title: `${data.message}`,
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Dialog open={isOpen}>
      <DialogContent className="min-w-full h-screen  overflow-y-auto">
        <AlertDialogHeader>
          <div className="w-full flex items-center justify-between border-b-2 pb-2 ">
            <DialogTitle>Informasi umum</DialogTitle>
            <DialogClose asChild onClick={onClose}>
              <Button type="button">
                <X className="h-4 w-4 mr-2" />
                Close
              </Button>
            </DialogClose>
          </div>
          <DialogDescription>
            <div className="container mx-auto max-w-5xl pt-10 mb-[200px]">
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
                    <FormField
                      control={form.control}
                      name="place_of_birth"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Tempat lahir</FormLabel>
                          <FormControl>
                            <Textarea {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="date_of_birth"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Tanggal lahir</FormLabel>
                          <FormControl>
                            <Input {...field} type="date" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="religion"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Agama</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Pilih" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {RELIGIONS.map((religion, index) => (
                                <SelectItem value={religion.value} key={index}>
                                  {religion.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="nationality"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Kewarganegaraan</FormLabel>
                          <FormControl>
                            <Input {...field} placeholder="wni, wna," />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="nik"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>NIK (Sesuai KTP)</FormLabel>
                          <FormControl>
                            <Input {...field} type="number" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="nisn"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>NISN</FormLabel>
                          <FormControl>
                            <Input {...field} type="number" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="npwp"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>NPWP</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="gender"
                      render={({ field }) => (
                        <FormItem className="space-y-3">
                          <FormLabel>Jenis Kelamin</FormLabel>
                          <FormControl>
                            <RadioGroup
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                              className="flex flex-row space-x-3"
                            >
                              <FormItem className="flex items-center space-x-3 space-y-0">
                                <FormControl>
                                  <RadioGroupItem value="laki-laki" />
                                </FormControl>
                                <FormLabel className="font-normal">
                                  Laki-laki
                                </FormLabel>
                              </FormItem>
                              <FormItem className="flex items-center space-x-3 space-y-0">
                                <FormControl>
                                  <RadioGroupItem value="perempuan" />
                                </FormControl>
                                <FormLabel className="font-normal">
                                  Perempuan
                                </FormLabel>
                              </FormItem>
                            </RadioGroup>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <br />
                  <hr />

                  <div className="mt-10">
                    <h1 className="font-bold text-lg">Alamat</h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
                      <FormField
                        control={form.control}
                        name="road"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Jalan</FormLabel>
                            <FormControl>
                              <Input {...field} placeholder="jl...." />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="hamlet"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Dusun</FormLabel>
                            <FormControl>
                              <Input {...field} placeholder="001/001" />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="subdistrict"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Kelurahan</FormLabel>
                            <FormControl>
                              <Input {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="district"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Kecamatan</FormLabel>
                            <FormControl>
                              <Input {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="residential_type"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Jenis Tempat Tinggal</FormLabel>
                            <Select
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Pilih" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {RESIDENTIAL_TYPES.map((resident, index) => (
                                  <SelectItem
                                    value={resident.value}
                                    key={index}
                                  >
                                    {resident.label}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="transportation"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Jenis Transportasi</FormLabel>
                            <Select
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Pilih" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {TRANSPORTATIONS.map((item, index) => (
                                  <SelectItem value={item.value} key={index}>
                                    {item.label}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="telphone"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Telepon</FormLabel>
                            <FormControl>
                              <Input {...field} type="number" />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="phone_number"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>No. HP/WA</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                type="number"
                                placeholder="masukkan nomor yang aktif"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="kps_beneficiary"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Penerima KPS</FormLabel>
                            <Select
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Pilih" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                <SelectItem value="ya">Ya</SelectItem>
                                <SelectItem value="tidak">Tidak</SelectItem>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="practical_training_location"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Nama Tempat sekolah PPL</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                placeholder="masukkan nama sekolah"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="school_principals_account_number"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>No. rekening Kepala sekolah</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                placeholder="masukkan nomor rekening..."
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="field_experience_supervisor"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              Nama Kepala Sekolah/Penganggun Jawab PPL
                            </FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                placeholder="masukkan nama kepala sekolah..."
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="serdik_shipping_address"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Alamat pengiriman serdik</FormLabel>
                            <FormControl>
                              <Textarea
                                {...field}
                                placeholder="masukkan alamat"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>

                  <div className="mt-10">
                    <Button type="submit" disabled={isLoading}>
                      {isLoading && (
                        <RotateCw className="mr-2 h-4 w-4 animate-spin" />
                      )}
                      Simpan
                    </Button>
                  </div>
                </form>
              </Form>
            </div>
          </DialogDescription>
        </AlertDialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalBiodata;
