import { Skeleton } from "../../../../components/ui/skeleton";

const ViewSkeleton = () => {
  return (
    <div className="container mx-auto max-w-4xl">
      <div className="space-y-2 mt-10">
        <div className="w-full flex justify-between gap-x-10">
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-[100px]" />
        </div>
        <br />
        <div className="mt-5">
          <Skeleton className="h-20 w-full " />
        </div>

        <div className="mt-5">
          <Skeleton className="h-20 w-full " />
        </div>

        <div className="mt-5">
          <Skeleton className="h-20 w-full " />
        </div>
      </div>
    </div>
  );
};

export default ViewSkeleton;
