import React, { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button";
import { AlertCircle, Camera, Pencil } from "lucide-react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../../../components/ui/alert";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useToast } from "../../../components/ui/use-toast";
import { Skeleton } from "../../../components/ui/skeleton";

type UserDashboardProps = {
  id: string;
  email: string;
  name: string;
  period: {
    category_name: string;
    wave: string;
    year: string;
    date_start: string;
    date_end: string;
  };
};

const Dashboard = () => {
  const { toast } = useToast();

  const [user, setUser] = useState<UserDashboardProps | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    setIsLoading(true);
    await api
      .get("student")
      .then(({ data }) => {
        setUser(data.data.user);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/student/re-enrollment");
  };

  return (
    <div className="container mx-auto max-w-7xl">
      <div className="w-full flex md:flex-row flex-col mt-10 md:gap-x-5 gap-x-0">
        {!isLoading && (
          <Alert className="bg-yellow-100 border border-yellow-500">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Hy {user?.name}!</AlertTitle>
            <AlertDescription>
              Anda sedang berada di seleksi tahap {user?.period.wave}{" "}
              pendaftaran Program PPG -{" "}
              <span className="uppercase">{user?.period.category_name}</span>
              <p className="text-sm font-semibold mt-2">
                Anda memiliki waktu hingga tanggal {user?.period.date_end} untuk
                menyelesaikan pengisian pada data Biodata Diri.
              </p>
              <Button onClick={handleClick} className="mt-4">
                <Pencil className="h-4 w-4 mr-2" />
                Lapor diri
              </Button>
            </AlertDescription>
          </Alert>
        )}

        {isLoading && <Skeleton className="w-full h-[160px]" />}
      </div>
    </div>
  );
};

export default Dashboard;
