export const RELIGIONS = [
  { value: "islam", label: "Islam" },
  { value: "kristen katolik", label: "Kristen Katolik" },
  { value: "kristen protestan", label: "Kristen Protestan" },
  { value: "budha", label: "Budha" },
  { value: "hindu", label: "Hindu" },
  { value: "khonghucu", label: "Khonghucu" },
  { value: "lainnya", label: "Lainnya" },
];

export const RESIDENTIAL_TYPES = [
  { value: "rumah sendiri", label: "Rumah Sendiri" },
  { value: "rumah orang tua", label: "Rumah Orang Tua" },
  { value: "apartemen", label: "Apartemen" },
  { value: "kos/kontrakan", label: "Kos/Kontrakan" },
  { value: "rumah dinas", label: "Rumah Dinas" },
  { value: "asrama", label: "Asrama" },
  { value: "hotel", label: "Hotel" },
  { value: "lainnya", label: "Lainnya" },
];

export const TRANSPORTATIONS = [
  { value: "motor ", label: "Motor" },
  { value: "mobil pribadi", label: "Mobil Pribadi" },
  { value: "kendaraan umum/bus", label: "Kendaraan Umum/Bus" },
  { value: "kereta api", label: "Kereta Api" },
  { value: "sepeda", label: "Sepeda" },
  { value: "jalan kaki", label: "Jalan Kaki" },
  { value: "ojek online", label: "Ojek Online" },
  { value: "transportasi umum laut", label: "Transportasi Umum Laut" },
  { value: "pesawat terbang", label: "Pesawat Terbang" },
  { value: "lainnya", label: "Lainnya" },
];

export const JOBS = [
  { value: "petani ", label: "Petani" },
  { value: "karyawan swasta", label: "Karyawan Swasta" },
  { value: "pns/asn", label: "PNS/ASN" },
  { value: "nelayan", label: "Nelayan" },
  { value: "ibu rumah tangga", label: "Ibu Rumah Tangga" },
  { value: "pensiunan", label: "Pensiunan" },
  { value: "buruh pabrik", label: "Buruh Pabrik" },
  { value: "tni/polri", label: "TNI/POLRI" },
  { value: "pengusaha", label: "Pengusaha" },
  { value: "guru/dosen", label: "Guru Dosen" },
  { value: "dokter", label: "Dokter" },
  { value: "perawat", label: "Perawat" },
  { value: "teknisi", label: "Teknisi" },
  { value: "Penyiar", label: "Penyiar" },
  { value: "lainnya", label: "Lainnya" },
];

export const EDUCATION_LEVELS = [
  { value: "tidak menempuh pendidikan ", label: "Tidak Menempuh Pendidikan" },
  { value: "sd/sederajat", label: "SD/Sederajat" },
  { value: "smp/sederajat", label: "SMP/Sederajat" },
  { value: "sma/sederajat", label: "SMA/Sederajat" },
  { value: "diploma", label: "Diploma (D1-D4)" },
  { value: "sarjana (S1)", label: "Sarjana (S1)" },
  { value: "magister (S2)", label: "Magister (S2)" },
  { value: "doktor (S3)", label: "Doktor (S3)" },
  { value: "lainnya", label: "Lainnya" },
];

export const SALARY = [
  { value: "kurang dari 1 juta ", label: "< 1 juta" },
  { value: "1 juta - 2 juta", label: "1 Juta - 2 Juta" },
  { value: "2 juta - 3 juta", label: "2 Juta - 3 Juta" },
  { value: "3 juta - 5 juta", label: "3 Juta - 5 Juta" },
  { value: "lebih dari 5 juta", label: "> 5 Juta" },
];
