import React from "react";
import { Skeleton } from "../../../components/ui/skeleton";

const SkeletonLoading = () => {
  return (
    <div className="w-full flex gap-x-10">
      <Skeleton className="h-8 w-[200px]" />
      <Skeleton className="h-8 w-[200px]" />
      <Skeleton className="h-8 w-[200px]" />
      <Skeleton className="h-8 w-[100px]" />
    </div>
  );
};

export default SkeletonLoading;
