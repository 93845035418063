import { useEffect, useState } from "react";
import { useToast } from "../../../../components/ui/use-toast";
import { Button } from "../../../../components/ui/button";
import { X } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Skeleton } from "../../../../components/ui/skeleton";
import api from "../../../../services/api";
import StudentAvatar from "./student-avatar";
import StudentChangePasswordForm from "./student-change-password-form";
import {
  ParentInformation,
  StudentDocument,
  StudentInformation,
} from "../../../../types";
import StudentBio from "./student-bio";
import StudentParents from "./student-parents";
import { Document } from "../../../../types";
import StudentDocuments from "./student-document";

interface ModalDetailProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

type DataProps = {
  user: {
    id: string;
    name: string;
    email: string;
    avatar: string;
    uniqId: string;
    documents: StudentDocument[];
    parents: [];
    bio: StudentInformation;
  };
};

const ModalDetail = ({ isOpen, onClose, id }: ModalDetailProps) => {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [student, setStudent] = useState<DataProps>();
  const [parent, setParent] = useState<ParentInformation | null>(null);
  const [documents, setDocuments] = useState<Document[] | []>([]);

  useEffect(() => {
    getStundet();
  }, []);

  const getStundet = async () => {
    setIsLoading(true);
    await api
      .get("re-enrollments/" + id)
      .then(({ data }) => {
        setStudent(data.data);
        setParent(data.data.parents);
        setDocuments(data.data.documents);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 500) {
            toast({
              title: "Server error. Please try again later.",
              variant: "destructive",
            });
          } else if (err.response.status === 400) {
            toast({
              title: `${err.response.data.message}`,
              variant: "destructive",
            });
          }
        } else {
          toast({
            title: `Something went wrong`,
            variant: "destructive",
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Dialog open={isOpen}>
        <DialogContent className="min-w-full min-h-screen overflow-auto max-h-[80vh]">
          <DialogHeader>
            <div className="w-full flex items-center justify-between border-b-2 pb-2">
              <DialogTitle>Detail data mahasiswa</DialogTitle>
              <DialogClose asChild onClick={onClose}>
                <Button type="button">
                  <X className="h-4 w-4 mr-2" />
                  Close
                </Button>
              </DialogClose>
            </div>
            <DialogDescription>
              <div className="container mx-auto max-w-7xl pt-10">
                {!isLoading && (
                  <div className="w-full flex md:flex-row flex-col md:gap-x-8 gap-x-0">
                    {!isLoading && student !== undefined && (
                      <StudentAvatar name={student.user.name} />
                    )}

                    <div className="md:w-3/4 w-full md:mt-0 mt-5 md:ml-6 ml-0">
                      <StudentChangePasswordForm studentId={id} />

                      {student !== undefined && (
                        <StudentBio data={student.user.bio} />
                      )}

                      {student !== undefined && (
                        <StudentParents data={parent} />
                      )}
                      {student !== undefined && (
                        <StudentDocuments data={documents} />
                      )}
                    </div>
                  </div>
                )}
                {isLoading && <SkeletonLoading />}
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalDetail;

const SkeletonLoading = () => {
  return (
    <div className="flex gap-x-4">
      <div className="w-1/5">
        <Skeleton className=" h-[250px] rounded" />
        <Skeleton className="w-full h-[20px] rounded mt-4" />
      </div>
      <div className="w-4/5">
        <Skeleton className="w-full h-[40px] rounded" />
        <Skeleton className="w-full h-[506px] rounded mt-4" />

        <Skeleton className="w-full h-[40px] rounded mt-7" />
        <Skeleton className="w-full h-[40px] rounded mt-4" />
      </div>
    </div>
  );
};
