import React from "react";
import { useLocation } from "react-router-dom";
import { useToast } from "../../../../components/ui/use-toast";
import { Button } from "../../../../components/ui/button";
import api from "../../../../services/api";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ExportData = () => {
  let query = useQuery();
  const { toast } = useToast();

  const params = {
    category: query.get("category"),
    wave: query.get("wave"),
    year: query.get("year"),
  };

  const handleExport = async () => {
    try {
      const response = await api.get("re-enrollments/export", {
        params: params,
        responseType: "blob",
      });

      const file = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", "users.xlsx");
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch (err) {
      const error = err as any;
      if (error.response) {
        if (error.response.status === 500) {
          toast({
            title: "Server error. Please try again later.",
            variant: "destructive",
          });
        } else if (error.response.status === 400) {
          toast({
            title: `${error.response.data.message}`,
            variant: "destructive",
          });
        }
      } else {
        toast({
          title: `Something went wrong`,
          variant: "destructive",
        });
      }
    }
  };

  return (
    <div>
      <Button variant="outline" onClick={handleExport}>
        Export excel
      </Button>
    </div>
  );
};

export default ExportData;
