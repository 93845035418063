import { MoreHorizontal } from "lucide-react";
import { Button } from "../../../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../../../../../components/ui/dropdown-menu";
import { TableCell, TableRow } from "../../../../../components/ui/table";
import { useState } from "react";
import ModalEdit from "./modal-edit";
import ModalDelete from "./modal-delete";

interface TableItemProps {
  id: string;
  name: string;
  type_files: [];
  isRequired: number;
  isActive: number;
}

const TableItem = ({
  id,
  name,
  type_files,
  isRequired,
  isActive,
}: TableItemProps) => {
  const [isModalEditOpen, setModalEditOpen] = useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<TableItemProps>();

  const handleEditClick = () => {
    setSelectedData({ id, name, type_files, isRequired, isActive });
    setModalEditOpen(true);
  };

  return (
    <>
      <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell>
          {type_files.map((type, index) => (
            <p>{type} ,</p>
          ))}
        </TableCell>
        <TableCell>{isRequired}</TableCell>
        <TableCell>{isActive}</TableCell>
        <TableCell>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DropdownMenuItem onClick={handleEditClick}>
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setModalDeleteOpen(true)}>
                Hapus
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </TableCell>
      </TableRow>
      {isModalEditOpen && (
        <ModalEdit
          defaultData={
            selectedData || {
              id: "",
              name: "",
              type_files: [],
              isRequired: 0,
              isActive: 0,
            }
          }
          onClose={() => setModalEditOpen(false)}
        />
      )}
      {isModalDeleteOpen && (
        <ModalDelete id={id} onClose={() => setModalDeleteOpen(false)} />
      )}
    </>
  );
};

export default TableItem;
